import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { ExternalLink } from 'lucide-react';

// Enhanced sound system with volume control, error handling and iOS compatibility
const playSound = (src, volume = 0.5) => {
  try {
    // Create audio context for iOS compatibility
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioCtx = new AudioContext();

    const audio = new Audio(src);
    audio.volume = volume;

    // Enable audio on iOS
    document.addEventListener('touchstart', () => {
      audioCtx.resume();
    }, { once: true });

    // Add fade in/out effects with optimized intervals
    audio.addEventListener('play', () => {
      let vol = 0;
      const fadeIn = setInterval(() => {
        if (vol < volume) {
          vol += 0.1;
          audio.volume = vol;
        } else {
          clearInterval(fadeIn);
        }
      }, 50);
    });

    // Handle errors gracefully
    audio.play().catch(err => console.log('Audio play error:', err));
  } catch (err) {
    console.log('Sound system error:', err);
  }
};

// Detect mobile/tablet devices
const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

// Optimized Matrix Rain Effect with enhanced performance
const MatrixRain = React.memo(() => {
  const [columns, setColumns] = useState([]);
  const canvasRef = useRef(null);
  const animationFrameIdRef = useRef(null);
  const lastTimeRef = useRef(0);

  // Optimized color palette with reduced glow effects for mobile
  const colors = useMemo(() => [
    { color: '#ff00ff', glow: '0 0 10px #ff00ff' }, // Reduced glow
    { color: '#00ffff', glow: '0 0 10px #00ffff' },
    { color: '#ff1493', glow: '0 0 10px #ff1493' },
    { color: '#4169e1', glow: '0 0 10px #4169e1' },
    { color: '#9400d3', glow: '0 0 10px #9400d3' }
  ], []);

  // Optimized raindrop creation with mobile-friendly sizing
  const createRaindrops = useCallback(() => {
    const chars = 'ワイフフォローアニメデジタル無限夢来世界';
    // Adjust font size for mobile
    const baseFontSize = Math.min(window.innerWidth, window.innerHeight) / 40;
    // Reduce number of columns on mobile
    const isMobile = window.innerWidth < 768;
    const columnSpacing = isMobile ? 1.2 : 0.6;
    const columnCount = Math.ceil(window.innerWidth / (baseFontSize * columnSpacing));
    
    // Create fewer raindrops on mobile
    return Array.from({ length: isMobile ? columnCount/2 : columnCount }).map((_, index) => ({
      char: chars[Math.floor(Math.random() * chars.length)],
      x: (index * baseFontSize * columnSpacing),
      y: Math.random() * window.innerHeight * -1,
      z: Math.random() * 100, // Reduced z-depth for better performance
      opacity: Math.random() * 0.5 + 0.5,
      speed: Math.random() + 0.5, // Reduced speed range
      colorIndex: Math.floor(Math.random() * colors.length),
      fontSize: baseFontSize * (Math.random() * 0.2 + 0.9),
      rotationX: Math.random() * 30, // Reduced rotation
      rotationY: Math.random() * 30,
      rotationZ: Math.random() * 30,
    }));
  }, [colors]);

  useEffect(() => {
    if (isMobileDevice()) {
      return;
    }
    
    setColumns(createRaindrops());
    const chars = 'ワイフフォローアニメデジタル無限夢来世界';

    // Reduced FPS on mobile
    const isMobile = window.innerWidth < 768;
    const fps = isMobile ? 30 : 60;
    const interval = 1000 / fps;

    const animate = (currentTime) => {
      if (!animationFrameIdRef.current) return;

      if (currentTime - lastTimeRef.current >= interval) {
        lastTimeRef.current = currentTime;

        setColumns(prev => prev.map(drop => {
          const updates = {
            y: drop.y > window.innerHeight ? window.innerHeight * -0.2 : drop.y + drop.speed,
            z: drop.z > 100 ? 0 : drop.z + drop.speed / 8,
          };

          // Reduce random updates for better performance
          if (Math.random() < 0.01) {
            updates.char = chars[Math.floor(Math.random() * chars.length)];
            updates.opacity = Math.random() * 0.5 + 0.5;
            updates.colorIndex = Math.floor(Math.random() * colors.length);
          }

          if (Math.random() < 0.02) {
            updates.rotationX = (drop.rotationX + 0.5) % 30;
            updates.rotationY = (drop.rotationY + 0.5) % 30;
            updates.rotationZ = (drop.rotationZ + 0.5) % 30;
          }

          return { ...drop, ...updates };
        }));
      }

      animationFrameIdRef.current = requestAnimationFrame(animate);
    };

    animationFrameIdRef.current = requestAnimationFrame(animate);

    // Debounced resize handler
    let resizeTimeout;
    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setColumns(createRaindrops());
      }, 250);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
        animationFrameIdRef.current = null;
      }
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [createRaindrops, colors]);

  // Use transform3d for hardware acceleration
  return (
    <div className="fixed inset-0 pointer-events-none perspective-1000">
      {/* Add noise overlay */}
      <div className="noise" />
      
      {/* Add scan overlay */}
      <div className="overlay" />
      
      {columns.map((drop, i) => (
        <div
          key={i}
          className="absolute will-change-transform"
          style={{
            left: `${drop.x}px`,
            top: `${drop.y}px`,
            transform: `translate3d(0,0,${drop.z}px) 
                       rotateX(${drop.rotationX}deg) 
                       rotateY(${drop.rotationY}deg) 
                       rotateZ(${drop.rotationZ}deg)`,
            opacity: drop.opacity * (1 - drop.z / 500),
            color: colors[drop.colorIndex].color,
            textShadow: colors[drop.colorIndex].glow,
            fontSize: `${drop.fontSize * (1 - drop.z / 1000)}px`,
            fontFamily: '"Inconsolata", "Press_Start_2P", monospace',
            filter: `blur(${Math.random() * 0.3}px)`, // Reduced blur
          }}
        >
          {drop.char}
        </div>
      ))}
      <canvas ref={canvasRef} className="absolute inset-0" />
    </div>
  );
});

// Enhanced Y2K Boot Screen Title with holographic effects
const BootTitle = React.memo(({ onComplete }) => {
  const [bootPhase, setBootPhase] = useState(0);
  const [glitchIntensity, setGlitchIntensity] = useState(0);
  const [showLinkStart, setShowLinkStart] = useState(false);
  const [opacity, setOpacity] = useState(1);

  // Set initial black background
  useEffect(() => {
    document.body.style.backgroundColor = 'black';
    return () => {
      document.body.style.backgroundColor = 'black';
    };
  }, []);

  useEffect(() => {
    // Play loading transition sound at max volume and wait for completion
    const audio = new Audio('/loading.mp3');
    audio.volume = 1.0;
    audio.play().catch(err => console.log('Audio play error:', err));

    const glitchInterval = setInterval(() => {
      setGlitchIntensity(Math.random());
    }, 100);

    const phases = [
      { delay: 1000, effect: 'scanlines' },
      { delay: 1500, effect: 'static' },
      { delay: 1500, effect: 'hologram' },
      { delay: 1000, showButton: true }
    ];

    let timeouts = [];

    phases.forEach((phase, index) => {
      const timeout = setTimeout(() => {
        setBootPhase(index);
        if (phase.showButton) {
          setShowLinkStart(true);
        }
      }, phases.slice(0, index + 1).reduce((acc, p) => acc + p.delay, 0));
      timeouts.push(timeout);
    });

    return () => {
      timeouts.forEach(t => clearTimeout(t));
      clearInterval(glitchInterval);
    };
  }, [onComplete]);

  const getGlitchStyle = useCallback(() => ({
    transform: glitchIntensity > 0.8 ? `translate(${Math.random() * 10 - 5}px, ${Math.random() * 10 - 5}px)` : 'none',
    filter: glitchIntensity > 0.8 ? `hue-rotate(${Math.random() * 360}deg)` : 'none',
  }), [glitchIntensity]);

  const handleLinkStart = () => {
    playSound('/loading-transition.mp3', 0.1);
    setOpacity(0);
    setTimeout(() => {
      document.body.style.backgroundColor = 'black';
      onComplete();
    }, 1000);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-100 z-50 transition-opacity duration-1000" style={{ opacity }}>
      <div className="relative text-center p-4 sm:p-8">
        <div className={`transition-all duration-500 ${bootPhase >= 0 ? 'opacity-100' : 'opacity-0'}`}>
          <div className="text-sm sm:text-base text-cyan-400 font-mono mb-4 animate-pulse">
            INITIALIZING SYSTEM...
          </div>
        </div>
        
        <div 
          className={`transition-all duration-500 ${bootPhase >= 1 ? 'opacity-100 scale-100' : 'opacity-0 scale-95'}`}
          style={getGlitchStyle()}
        >
          <div 
            className="text-2xl sm:text-4xl md:text-6xl font-['Press_Start_2P'] text-cyan-400 mb-8"
            style={{
              textShadow: '0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #0ff',
              letterSpacing: '0.1em',
              animation: 'hologram 3s infinite'
            }}
          >
            WAIFU<span className="text-pink-400">VERSE</span>
          </div>
        </div>

        <div className={`space-y-2 transition-all duration-500 mt-8 ${bootPhase >= 2 ? 'opacity-100' : 'opacity-0'}`}>
          <div className="text-xs sm:text-sm text-cyan-400 font-mono relative">
            {['LOADING MODULES...', 'CHECKING SYSTEM INTEGRITY...', 'INITIALIZING NEURAL NETWORK...', 'SYSTEM READY'].map((text, index) => (
              <div 
                key={index}
                className="animate-typing"
                style={{ 
                  animationDelay: `${index * 300}ms`,
                  ...getGlitchStyle()
                }}
              >
                {text}
              </div>
            ))}
          </div>
        </div>

        {showLinkStart && (
          <button
            onClick={handleLinkStart}
            className="mt-8 px-8 py-4 bg-cyan-400/20 border-2 border-cyan-400 text-cyan-400 font-['Press_Start_2P'] 
                     hover:bg-cyan-400/30 transition-all duration-300 rounded-lg animate-pulse"
            style={{
              textShadow: '0 0 10px #0ff, 0 0 20px #0ff',
              boxShadow: '0 0 20px rgba(0, 255, 255, 0.3)'
            }}
          >
            LINK START
          </button>
        )}

        {/* Holographic overlay with reduced opacity */}
        {!isMobileDevice() && (
          <div className="absolute inset-0 pointer-events-none">
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-cyan-400/5 to-transparent animate-scan" />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-cyan-400/3 to-transparent animate-scan-vertical" />
          </div>
        )}
      </div>
    </div>
  );
});

// Loading Screen with enhanced holographic and particle effects
const LoadingScreen = React.memo(({ onLoadingComplete }) => {
  const [opacity, setOpacity] = useState(1);
  const [progress, setProgress] = useState(0);
  const [loadingText, setLoadingText] = useState('Initializing Universe...');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [particles, setParticles] = useState([]);

  // Set initial black background
  useEffect(() => {
    document.body.style.backgroundColor = 'black';
    return () => {
      document.body.style.backgroundColor = 'black';
    };
  }, []);

  const loadingPhrases = useMemo(() => [
    'Initializing Universe...',
    'Generating Waifus...',
    'Calibrating Reality...',
    'Synchronizing Dimensions...',
    'Almost Ready...'
  ], []);

  useEffect(() => {
    // Only generate particles on desktop
    if (!isMobileDevice()) {
      const particleCount = 50;
      const newParticles = Array.from({ length: particleCount }).map(() => ({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        size: Math.random() * 4 + 1,
        speedX: (Math.random() - 0.5) * 2,
        speedY: (Math.random() - 0.5) * 2,
        opacity: Math.random() * 0.5 + 0.3,
      }));
      setParticles(newParticles);
    }

    const progressInterval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(progressInterval);
          // Fade out transition
          setOpacity(0);
          // Complete loading after fade out
          setTimeout(() => {
            document.body.style.backgroundColor = 'black';
            onLoadingComplete();
          }, 1000);
          return 100;
        }
        return prev + 1;
      });
    }, 50);

    let phraseIndex = 0;
    const textInterval = setInterval(() => {
      phraseIndex = (phraseIndex + 1) % loadingPhrases.length;
      setLoadingText(loadingPhrases[phraseIndex]);
    }, 2000);

    let particleInterval;
    if (!isMobileDevice()) {
      particleInterval = setInterval(() => {
        setParticles(prev => prev.map(particle => ({
          ...particle,
          x: (particle.x + particle.speedX + window.innerWidth) % window.innerWidth,
          y: (particle.y + particle.speedY + window.innerHeight) % window.innerHeight,
          opacity: Math.sin(Date.now() / 1000) * 0.2 + 0.3,
        })));
      }, 16);
    }

    return () => {
      clearInterval(progressInterval);
      clearInterval(textInterval);
      if (particleInterval) {
        clearInterval(particleInterval);
      }
    };
  }, [onLoadingComplete, loadingPhrases]);

  return (
    <div 
      className="fixed inset-0 bg-black z-50 transition-opacity duration-1000 ease-in-out"
      style={{ opacity }}
    >
      {/* Particle effect - only on desktop */}
      {!isMobileDevice() && particles.map((particle, index) => (
        <div
          key={index}
          className="absolute rounded-full bg-cyan-400"
          style={{
            left: particle.x,
            top: particle.y,
            width: particle.size,
            height: particle.size,
            opacity: particle.opacity,
            boxShadow: `0 0 ${particle.size * 2}px #0ff`,
            transition: 'opacity 0.3s',
          }}
        />
      ))}

      <div className="relative h-full flex items-center justify-center">
        <div className="relative w-[300px] h-[300px] sm:w-[400px] sm:h-[400px]">
          <img
            src="/loading.jpg"
            alt="Loading Hologram"
            onLoad={() => setImageLoaded(true)}
            className={`w-full h-full object-contain transition-opacity duration-1000 ${
              imageLoaded ? 'opacity-60' : 'opacity-0'
            }`}
            style={{ 
              filter: 'brightness(1.2) contrast(1.1) saturate(1.2) hue-rotate(-10deg)',
              mixBlendMode: 'screen',
              animation: 'float 6s infinite ease-in-out',
              transform: `perspective(1000px) 
                         rotateX(${Math.sin(Date.now() / 1000) * 5}deg)
                         rotateY(${Math.cos(Date.now() / 1000) * 5}deg)`,
            }}
          />
          
          {/* Holographic effects - only on desktop */}
          {!isMobileDevice() && (
            <div className="absolute inset-0">
              <div className="absolute inset-0 bg-gradient-radial from-cyan-500/20 via-transparent to-transparent mix-blend-overlay animate-pulse" />
              <div className="absolute inset-0 animate-scanline" />
              <div className="absolute inset-0 bg-gradient-to-b from-transparent via-cyan-400/10 to-transparent animate-scan-vertical" />
            </div>
          )}
        </div>
        
        <div className="absolute inset-x-0 bottom-0 p-4 sm:p-8">
          <div className="max-w-md mx-auto space-y-4 backdrop-blur-sm bg-black/30 p-4 rounded-lg border border-cyan-400/20">
            <div className="text-cyan-400 font-['Press_Start_2P'] text-sm sm:text-base animate-pulse">
              {loadingText}
            </div>
            
            <div className="relative w-full h-2 bg-cyan-400/20 rounded-full overflow-hidden">
              <div 
                className="absolute h-full bg-cyan-400 transition-all duration-300 rounded-full"
                style={{ 
                  width: `${progress}%`,
                  boxShadow: '0 0 10px #0ff, 0 0 20px #0ff',
                  filter: 'brightness(1.2)',
                }}
              />
              <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/30 to-transparent animate-shine" />
            </div>
            
            <div className="text-right text-cyan-400 font-['Press_Start_2P'] text-xs">
              {progress}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

// Login component with enhanced cyberpunk styling and animations
const Login = React.memo(({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loginOpacity, setLoginOpacity] = useState(0);
  const [glitchIntensity, setGlitchIntensity] = useState(0);
  const [showLinkStart, setShowLinkStart] = useState(false);
  const [isWarping, setIsWarping] = useState(false);
  const [warpParticles, setWarpParticles] = useState([]);
  const [linkStartOpacity, setLinkStartOpacity] = useState(1);
  const [buttonGlitching, setButtonGlitching] = useState(false);
  const [fadeToBlack, setFadeToBlack] = useState(false);

  // Set initial black background
  useEffect(() => {
    document.body.style.backgroundColor = 'black';
    return () => {
      document.body.style.backgroundColor = 'black';
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setLoginOpacity(1), 100);
    setUsername('admin');
    setPassword('666666');

    const glitchInterval = setInterval(() => {
      setGlitchIntensity(Math.random());
    }, 100);

    return () => {
      clearTimeout(timer);
      clearInterval(glitchInterval);
    };
  }, []);

  useEffect(() => {
    if (isWarping && !isMobileDevice()) {
      // Create initial particles
      const particles = Array.from({ length: 200 }, () => ({
        x: window.innerWidth / 2 + (Math.random() - 0.5) * 200,
        y: window.innerHeight / 2 + (Math.random() - 0.5) * 200,
        z: 1000,
        size: Math.random() * 3 + 1,
        speed: Math.random() * 0.5 + 0.5, // Start slower
        opacity: Math.random(),
        angle: Math.random() * Math.PI * 2
      }));
      
      setWarpParticles(particles);

      let startTime = Date.now();
      let animationFrameId;
      
      const animate = () => {
        const elapsed = (Date.now() - startTime) / 1000; // Time in seconds
        const speedMultiplier = Math.min(10, 1 + elapsed * 2); // Gradually increase speed
        
        setWarpParticles(prevParticles => 
          prevParticles.map(p => ({
            ...p,
            z: p.z - p.speed * 10 * speedMultiplier,
            size: p.size * (1 + p.speed/100),
            opacity: Math.min(1, p.z / 500)
          })).filter(p => p.z > -100)
        );
        
        animationFrameId = requestAnimationFrame(animate);
      };

      animate();
      return () => {
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
        }
      };
    }
  }, [isWarping]);

  const handleLogin = useCallback(() => {
    if (username === 'admin' && password === '666666') {
      setLoginOpacity(0);
      setTimeout(() => {
        setShowLinkStart(true);
        setLoginOpacity(1);
      }, 500);
    } else {
      setError('ACCESS DENIED');
      setGlitchIntensity(1);
      setTimeout(() => setGlitchIntensity(0), 500);
    }
  }, [username, password]);

  const handleLinkStart = () => {
    playSound('/loading-transition.mp3', 0.1);
    
    if (isMobileDevice()) {
      setButtonGlitching(true);
      // Intense glitch effect for 1 second
      const glitchInterval = setInterval(() => {
        setGlitchIntensity(Math.random());
      }, 50);
      
      // After 1 second, start fade out
      setTimeout(() => {
        clearInterval(glitchInterval);
        setFadeToBlack(true);
        setLinkStartOpacity(0);
        // After fade completes, trigger login
        setTimeout(() => {
          document.body.style.backgroundColor = 'black';
          onLogin();
        }, 1000);
      }, 1000);
    } else {
      setFadeToBlack(true);
      setLinkStartOpacity(0);
      setIsWarping(true);
      setTimeout(() => {
        document.body.style.backgroundColor = 'black';
        onLogin();
      }, 3000);
    }
  };

  const getGlitchStyle = useCallback(() => ({
    transform: glitchIntensity > 0.8 ? `translate(${Math.random() * 10 - 5}px, ${Math.random() * 10 - 5}px)` : 'none',
    filter: glitchIntensity > 0.8 ? `hue-rotate(${Math.random() * 360}deg)` : 'none',
  }), [glitchIntensity]);

  return (
    <div className="min-h-screen bg-black text-cyan-400 flex items-center justify-center p-4 relative overflow-hidden">
      {!isMobileDevice() && <MatrixRain />}
      
      {/* Warp effect particles - only on desktop */}
      {isWarping && !isMobileDevice() && warpParticles.map((particle, index) => (
        <div
          key={index}
          className="absolute bg-cyan-400 rounded-full pointer-events-none will-change-transform"
          style={{
            left: `${particle.x + (particle.z * Math.cos(particle.angle) * 0.5)}px`,
            top: `${particle.y + (particle.z * Math.sin(particle.angle) * 0.5)}px`,
            width: `${particle.size}px`,
            height: `${particle.size}px`,
            opacity: particle.opacity,
            transform: `translateZ(0) scale(${1 - particle.z/1000})`,
            boxShadow: '0 0 5px #0ff',
          }}
        />
      ))}

      {!showLinkStart ? (
        <div 
          className="relative w-full max-w-[90vw] md:max-w-md space-y-4 md:space-y-6 font-['Press_Start_2P'] 
                     border-2 border-cyan-400 p-4 md:p-8 animate-glitch bg-black/30 backdrop-blur-sm
                     transition-all duration-500"
          style={{ 
            opacity: loginOpacity,
            ...getGlitchStyle(),
          }}
        >
          <h2 className="text-lg md:text-2xl text-center mb-4 md:mb-8 animate-pulse">
            {'>'} SYSTEM LOGIN
          </h2>
          <div className="space-y-4">
            <div>
              <label className="block mb-2 text-xs md:text-base">{'>'} USERNAME:</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full bg-transparent border-2 border-cyan-400 p-2 text-cyan-400 
                           focus:outline-none focus:border-pink-400 text-sm md:text-base
                           hover:border-cyan-300 transition-colors"
                style={{
                  WebkitAppearance: 'none',
                  boxShadow: '0 0 10px rgba(0, 255, 255, 0.2)',
                }}
              />
            </div>
            <div>
              <label className="block mb-2 text-xs md:text-base">{'>'} PASSWORD:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full bg-transparent border-2 border-cyan-400 p-2 text-cyan-400 
                           focus:outline-none focus:border-pink-400 text-sm md:text-base
                           hover:border-cyan-300 transition-colors"
                style={{
                  WebkitAppearance: 'none',
                  boxShadow: '0 0 10px rgba(0, 255, 255, 0.2)',
                }}
              />
            </div>
            {error && (
              <div 
                className="text-red-500 animate-glitch text-xs md:text-sm"
                style={getGlitchStyle()}
              >
                {error}
              </div>
            )}
            <button
              onClick={() => {
                playSound('/button-click.mp3');
                handleLogin();
              }}
              className="w-full border-2 border-cyan-400 p-2 hover:bg-cyan-400/20 
                       active:bg-cyan-400/30 transition-all duration-300 text-xs md:text-base mt-4
                       hover:border-cyan-300 hover:text-cyan-300"
              style={{
                boxShadow: '0 0 10px rgba(0, 255, 255, 0.2)',
              }}
            >
              {'>'} LOGIN
            </button>
          </div>
          {/* Holographic overlay */}
          <div className="absolute inset-0 pointer-events-none">
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-cyan-400/10 to-transparent animate-scan" />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-cyan-400/5 to-transparent animate-scan-vertical" />
          </div>
        </div>
      ) : (
        <div 
          className="relative font-['Press_Start_2P'] animate-fadeIn"
          style={{ 
            opacity: linkStartOpacity,
            transition: 'opacity 1s ease-out',
            ...buttonGlitching ? getGlitchStyle() : {}
          }}
        >
          <button
            onClick={handleLinkStart}
            className="text-3xl md:text-5xl text-cyan-400 border-4 border-cyan-400 px-8 py-4
                     hover:bg-cyan-400/20 active:bg-cyan-400/30 transition-all duration-300
                     hover:scale-105 hover:border-cyan-300 hover:text-cyan-300 animate-pulse
                     backdrop-blur-sm bg-black/30"
            style={{
              boxShadow: '0 0 20px rgba(0, 255, 255, 0.3), 0 0 40px rgba(0, 255, 255, 0.2)',
              textShadow: '0 0 10px rgba(0, 255, 255, 0.5)',
            }}
          >
            {'>'} LINK START
          </button>
          
          {/* Holographic effects for LINK START */}
          <div className="absolute inset-0 pointer-events-none">
            <div className="absolute inset-0 bg-gradient-radial from-cyan-500/20 via-transparent to-transparent mix-blend-overlay animate-pulse" />
            <div className="absolute inset-0 animate-scanline" />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-cyan-400/10 to-transparent animate-scan-vertical" />
          </div>
        </div>
      )}
    </div>
  );
});

// Content sections with enhanced styling, animations and interactions
const content = {
  introduction: (
    <div className="space-y-6 font-['Press_Start_2P'] text-cyan-400 animate-fadeIn p-4 relative overflow-hidden">
      <h2 className="text-xl sm:text-2xl animate-glitch relative z-10 hover:scale-105 transition-transform">{'>'} INTRODUCTION</h2>
      <div className="flex flex-col items-center mb-8 relative group">
        <img 
          src="/logo.png" 
          alt="Waifuverse Logo"
          className="w-48 sm:w-64 md:w-80 animate-glitch opacity-80 group-hover:opacity-100 transition-all duration-500 cursor-pointer"
          style={{
            filter: 'drop-shadow(0 0 15px #0ff) drop-shadow(0 0 25px #0ff)',
            animation: 'glitch 2s infinite, hologram 3s infinite, float 6s ease-in-out infinite'
          }}
          onClick={() => playSound('/button-click.mp3', 0.3)}
        />
        <div className="absolute inset-0 bg-gradient-to-r from-cyan-500/0 via-cyan-500/10 to-cyan-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
        <span className="mt-4 text-lg sm:text-xl font-bold animate-pulse text-pink-400"
              style={{
                textShadow: '0 0 10px #ff69b4, 0 0 20px #ff69b4',
                animation: 'bounce 2s infinite'
              }}>
          COMING SOON TO BASE
        </span>
      </div>
      <p className="leading-relaxed terminal-text animate-typing text-sm sm:text-base relative z-10 hover:text-cyan-300 transition-colors font-['Press_Start_2P']">
        WAIFUVERSE is a pioneering NFT project merging anime with blockchain technology.
      </p>
      <p className="leading-relaxed terminal-text animate-typing text-sm sm:text-base relative z-10 hover:text-cyan-300 transition-colors font-['Press_Start_2P']">
        Our genesis collection features 8888 unique Anime Waifu NFTs obtained through burning/minting.
      </p>
      <p className="leading-relaxed terminal-text animate-typing text-sm sm:text-base relative z-10 hover:text-cyan-300 transition-colors font-['Press_Start_2P']">
        Our mission is to establish a sustainable ecosystem generating continuous value for members through diverse utilities and benefits across many distinct phases.
      </p>
    </div>
  ),

  whitepaper: (
    <div className="space-y-6 font-['Press_Start_2P'] text-cyan-400 animate-fadeIn p-4 relative overflow-hidden">
      <h2 className="text-xl sm:text-2xl md:text-3xl animate-glitch relative z-10 hover:scale-105 transition-transform mb-6">{'>'} WHITEPAPER</h2>
      
      {/* Mobile View */}
      <div className="block sm:hidden">
        <a
          href="https://docs.google.com/document/d/e/2PACX-1vSm3lz_VfRtr7XMdjqHh9MSWE-kPJ1gLytttOhUhEX210T-G3IpG_ltrPa8wIQRDor0q9GkNOvgEtM-/pub"
          target="_blank"
          rel="noopener noreferrer"
          className="block w-full py-4 text-center rounded-lg border-2 border-cyan-400 hover:border-cyan-300 
                     bg-black/90 hover:bg-black/70 transition-all duration-500"
          onClick={() => playSound('/button-click.mp3', 0.2)}
        >
          <span className="text-sm">View Whitepaper</span>
          <ExternalLink size={16} className="inline-block ml-2 animate-pulse" />
        </a>
      </div>

      {/* Desktop View */}
      <div className="hidden sm:block relative w-full overflow-hidden rounded-lg border-2 border-cyan-400 
                      group hover:border-cyan-300 transition-colors duration-500 shadow-lg hover:shadow-cyan-400/20">
        <div className="relative pt-[75%] md:pt-[56.25%] w-full">
          <iframe 
            src="https://docs.google.com/document/d/e/2PACX-1vSm3lz_VfRtr7XMdjqHh9MSWE-kPJ1gLytttOhUhEX210T-G3IpG_ltrPa8wIQRDor0q9GkNOvgEtM-/pub?embedded=true"
            className="absolute top-0 left-0 w-full h-full bg-black/90"
            style={{
              filter: 'drop-shadow(0 0 10px #0ff)',
            }}
            title="Waifuverse Whitepaper"
          />
        </div>
        <div className="absolute inset-0 pointer-events-none">
          <div className="absolute inset-0 bg-gradient-to-r from-cyan-500/0 via-cyan-500/10 to-cyan-500/0 
                          opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-cyan-400/5 to-transparent animate-scan-vertical" />
        </div>
      </div>
    </div>
  ),

  roadmap: (
    <div className="roadmap space-y-8 font-['Press_Start_2P'] animate-fadeIn p-4">
      <h2 className="text-cyan-400 text-xl sm:text-2xl animate-glitch hover:scale-105 transition-transform">{'>'} ROADMAP_DATA</h2>

      {[
        {
          phase: 'PHASE_1: GENESIS',
          description: '8888 Anime Waifu NFTs + waifuverse.io development [🏰]',
          status: 'EXECUTING...',
          statusColor: 'text-yellow-400',
          animation: 'animate-pulse'
        },
        {
          phase: 'PHASE_2: OTAKU',
          description: '8888 MALE Otaku PFP NFTs [🎭]',
          status: 'PENDING',
          statusColor: 'text-gray-400',
          animation: 'animate-blink'
        },
        {
          phase: 'PHASE_3: TAMASHI AI',
          description: 'Tamashi, meaning "soul" in Japanese, involving AI-powered Waifu personalities [🤖]',
          status: 'PENDING',
          statusColor: 'text-gray-400',
          animation: 'animate-blink'
        },
        {
          phase: 'PHASE_4: FASHION',
          description: 'Japanese designer collaborations & sustainable merch [👗]',
          status: 'PENDING',
          statusColor: 'text-gray-400',
          animation: 'animate-blink'
        },
        {
          phase: 'PHASE_5: MEME CARDS',
          description: 'Web3 & anime culture meme trading cards [🎴]',
          status: 'PENDING',
          statusColor: 'text-gray-400',
          animation: 'animate-blink'
        }
      ].map((phase, index) => (
        <div 
          key={index} 
          className="group hover:scale-105 transition-all duration-500 p-4 rounded-lg bg-cyan-900/5 hover:bg-cyan-900/15"
          onClick={() => playSound('/button-click.mp3', 0.15)}
        >
          <h3 className="text-cyan-400 text-lg sm:text-xl group-hover:text-cyan-300">{'>'} {phase.phase}</h3>
          <p className="mt-2 terminal-text animate-typing text-sm sm:text-base group-hover:text-cyan-300">{phase.description}</p>
          <div className={`mt-1 flex items-center gap-2 ${phase.statusColor} ${phase.animation}`}>
            <span className="inline-block w-2 h-2 rounded-full bg-current"></span>
            <span className="text-sm sm:text-base">STATUS: {phase.status}</span>
          </div>
        </div>
      ))}

      <style jsx>{`
        @keyframes blink {
          0%, 100% { opacity: 0.3; }
          50% { opacity: 1; }
        }
        .animate-blink {
          animation: blink 2s ease-in-out infinite;
        }
      `}</style>
    </div>
  ),

  team: (
    <div className="space-y-8 font-['Press_Start_2P'] animate-fadeIn p-4 relative">
      <h2 className="text-xl sm:text-2xl text-cyan-400 animate-glitch hover:scale-105 transition-transform">{'>'} TEAM_DATA</h2>
      
      {[
        {
          role: "FOUNDER & TECH_VISIONARY",
          name: "ELIJAH MATTHEW MOSES",
          img: "/efficio.jpg",
          alt: "Efficio",
          bio: "Pioneer in blockchain since 2012, founder of Animus Regnum LLC and Ignis AI Labs, with expertise in AI development and trading."
        },
        {
          role: "FOUNDER & DIRECTOR",
          name: "BEN KHAMHAENG",
          img: "/ben.jpg",
          alt: "Ben",
          bio: "Business Development Manager at Agora Visuals, Web3 veteran, former PwC professional, currently specializing in experiential and luxury retail partnerships."
        }
      ].map((member, index) => (
        <div key={index} className="space-y-4 transform hover:scale-105 transition-all duration-500 group">
          <h3 className="text-lg sm:text-xl text-cyan-400 group-hover:text-cyan-300">{'>'} {member.role}</h3>
          <div className="flex flex-col sm:flex-row items-center gap-4 p-4 rounded-lg bg-cyan-900/10 group-hover:bg-cyan-900/20 transition-all">
            <div className="relative">
              <img 
                src={member.img}
                alt={member.alt}
                className="w-32 h-32 sm:w-48 sm:h-48 object-cover rounded-lg opacity-80 group-hover:opacity-100 transition-all duration-500"
                style={{
                  filter: 'drop-shadow(0 0 15px #0ff) drop-shadow(0 0 25px #0ff)',
                  animation: 'hologram 3s infinite'
                }}
                onClick={() => playSound('/button-click.mp3', 0.2)}
              />
              <div className="absolute inset-0 bg-gradient-to-br from-cyan-500/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-lg" />
            </div>
            <div className="flex-1">
              <h4 className="text-base sm:text-lg animate-pulse mt-2 sm:mt-0 group-hover:text-cyan-300">{member.name}</h4>
              <p className="leading-relaxed terminal-text animate-typing text-sm sm:text-base mt-2 group-hover:text-cyan-300">
                {member.bio}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  ),

  mint: (
    <div className="space-y-8 font-['Press_Start_2P'] animate-fadeIn p-4">
      <h2 className="text-xl sm:text-2xl text-pink-400 animate-glitch hover:scale-105 transition-transform">{'>'} MINT_INFO</h2>
      
      <div className="flex justify-center">
        <div className="relative w-64 sm:w-96 h-64 sm:h-96 perspective-1000">
          <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-pink-500/20 via-pink-400/30 to-pink-500/20 animate-pulse" />
          <img 
            src="/mint.png"
            alt="Waifu NFT"
            className="absolute w-full h-full object-contain rounded-lg shadow-lg transform-style-3d animate-hover opacity-60"
            style={{
              filter: 'drop-shadow(0 0 15px #ff69b4) drop-shadow(0 0 25px #ff69b4)',
              transform: 'rotateY(0deg)',
              animation: 'hover 3s ease-in-out infinite',
              backfaceVisibility: 'visible'
            }}
          />
          <div className="absolute inset-0 rounded-lg border-2 border-pink-400/50 animate-pulse" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-pink-400/10 to-transparent animate-scan-vertical" />
        </div>
      </div>

      <style jsx>{`
        @keyframes hover {
          0%, 100% {
            transform: rotateY(-5deg);
          }
          50% {
            transform: rotateY(5deg);
          }
        }
        .animate-hover {
          animation: hover 3s ease-in-out infinite;
          transform-style: preserve-3d;
        }
      `}</style>

      <div className="flex flex-col sm:flex-row justify-center gap-4 mt-8">
        <button 
          className="w-full sm:w-auto px-8 py-4 bg-pink-500/20 rounded-lg transform hover:scale-110 transition-all duration-300 border-2 border-pink-400/50 shadow-[0_0_25px_rgba(236,72,153,0.6)] backdrop-blur-sm relative overflow-hidden before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-pink-400/20 before:to-transparent before:translate-x-[-200%] hover:before:translate-x-[200%] before:transition-transform before:duration-700 hover:border-pink-400/80 hover:shadow-[0_0_35px_rgba(236,72,153,0.7)] hover:bg-pink-500/30 pulse-animation"
          onClick={() => playSound('/button-click.mp3', 0.15)}
        >
          <span className="relative z-10 text-pink-100 text-lg font-bold tracking-wider hover:text-white transition-colors">MINT</span>
        </button>
        <button 
          className="w-full sm:w-auto px-8 py-4 bg-pink-500/20 rounded-lg transform hover:scale-110 transition-all duration-300 border-2 border-pink-400/50 shadow-[0_0_25px_rgba(236,72,153,0.6)] backdrop-blur-sm relative overflow-hidden before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-pink-400/20 before:to-transparent before:translate-x-[-200%] hover:before:translate-x-[200%] before:transition-transform before:duration-700 hover:border-pink-400/80 hover:shadow-[0_0_35px_rgba(236,72,153,0.7)] hover:bg-pink-500/30 pulse-animation"
          onClick={() => playSound('/button-click.mp3', 0.15)}
        >
          <span className="relative z-10 text-pink-100 text-lg font-bold tracking-wider hover:text-white transition-colors">BURN</span>
        </button>
      </div>

      <div className="space-y-6 text-sm sm:text-base">
        <p className="terminal-text animate-typing text-pink-300 leading-relaxed">
          The Genesis Collection comprises 8888 Anime Waifu NFTs, each representing a unique and exclusive character within the WAIFUVERSE ecosystem. These NFTs grant holders access to a plethora of benefits, including community governance, utility access, and exclusive events.
        </p>

        <ul className="space-y-2 text-pink-300 list-disc pl-4">
          <li className="terminal-text">5555 NFTs will be reserved for burns</li>
          <li className="terminal-text">3333 for Public Mint at 0.021 ETH</li>
        </ul>
      </div>
    </div>
  ),

  referral: (
    <div className="space-y-8 font-['Press_Start_2P'] animate-fadeIn p-4">
      <h2 className="text-xl sm:text-2xl text-pink-400 animate-glitch hover:scale-105 transition-transform">{'>'} REFERRAL_PROGRAM</h2>
      
      <div className="space-y-6">
        <h3 className="text-lg sm:text-xl text-pink-400">{'>'} ERC-987 REFERRAL SYSTEM</h3>

        <div className="flex justify-center mt-8">
          <button 
            className="w-full sm:w-auto px-8 py-4 bg-pink-500/20 rounded-lg transform hover:scale-110 transition-all duration-300 border-2 border-pink-400/50 shadow-[0_0_25px_rgba(236,72,153,0.6)] backdrop-blur-sm relative overflow-hidden before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-pink-400/20 before:to-transparent before:translate-x-[-200%] hover:before:translate-x-[200%] before:transition-transform before:duration-700 hover:border-pink-400/80 hover:shadow-[0_0_35px_rgba(236,72,153,0.7)] hover:bg-pink-500/30 pulse-animation"
            onClick={() => playSound('/button-click.mp3', 0.15)}
          >
            <span className="relative z-10 font-['Press_Start_2P'] tracking-wider text-pink-100 hover:text-white transition-colors">REFERRAL CODE</span>
          </button>
        </div>

        <div 
          className="group hover:scale-105 transition-all duration-500 p-4 rounded-lg bg-pink-900/5 hover:bg-pink-900/15"
          onClick={() => playSound('/button-click.mp3', 0.15)}
        >
          <h3 className="text-pink-400 text-lg sm:text-xl group-hover:text-pink-300">{'>'} INVITE FRIENDS</h3>
          <p className="mt-2 terminal-text animate-typing text-sm sm:text-base group-hover:text-pink-300">
            EVERYONE IS ELIGIBLE! UNLIMITED REFERRALS! Share your referral code to earn 13% of the mint price!
          </p>
        </div>

        <div className="space-y-16"></div>

        <div className="space-y-6">
          <div className="w-full max-h-[70vh] overflow-y-auto scrollbar-thin scrollbar-thumb-pink-400 scrollbar-track-pink-900/20 pr-4">
            <h4 className="text-pink-400">{'>'} WHAT IS ERC-987?</h4>
            <ul className="space-y-2 text-pink-300 text-sm sm:text-base list-disc pl-4">
              <li className="terminal-text">Innovative Technology: ERC-987 is a new standard for smart contracts that incorporates a referral reward mechanism directly within the minting process. This means that when a new NFT is minted using a referral code, the referrer instantly receives a reward, all automated through the smart contract.</li>
              <li className="terminal-text">Instant Referral Rewards: For each successful referral resulting in an NFT mint, the referring holder earns a 13% commission, automatically paid out to their wallet.</li>
            </ul>

            <h4 className="text-pink-400 mt-6">{'>'} HOW IT WORKS</h4>
            <ul className="space-y-2 text-pink-300 text-sm sm:text-base list-disc pl-4">
              <li className="terminal-text">Obtaining and Activating a Referral Code: Eligible participants can obtain and activate their unique referral code from their personal dashboard on our site.</li>
              <li className="terminal-text">Automatic Reward Distribution: Once the NFT is minted with a referral code, the smart contract automatically distributes the 13% reward to the referrer's wallet.</li>
            </ul>
          </div>

          <div className="w-full max-h-[70vh] overflow-y-auto scrollbar-thin scrollbar-thumb-pink-400 scrollbar-track-pink-900/20 pr-4">
            <h4 className="text-pink-400 text-lg mb-4">{'>'} REFERRAL SYSTEM RULES</h4>

            <div className="space-y-6 text-pink-300 text-sm">
              <div>
                <h5 className="font-bold mb-2">Using Referral Codes</h5>
                <p className="terminal-text">Obtain and Activate a Referral Code: Everyone is eligible to recieve a unique referral code, which they must first activate by minting it themselves from the referral page. This initial activation is necessary for the referral code to become functional.</p>
              </div>

              <div>
                <h5 className="font-bold mb-2">Sharing the Code</h5>
                <p className="terminal-text">Once activated, users may share their referral code with friends and acquaintances interested in minting Waifuverse NFTs.</p>
              </div>

              <div>
                <h5 className="font-bold mb-2">Code Usage by New Members</h5>
                <p className="terminal-text">Minters must enter the user's activated referral code when they mint an NFT. This ensures the referral is registered and tracked by our smart contract.</p>
              </div>

              <div>
                <h5 className="font-bold mb-2">No Retroactive Credits</h5>
                <p className="terminal-text">The referral code must be entered at the time of minting. If a new member fails to use the referral code during their mint, the referral will not be counted, and there will be no possibility to retroactively apply the referral.</p>
              </div>

              <div>
                <h5 className="font-bold mb-2">Automatic Payouts</h5>
                <p className="terminal-text">Referral earnings will be automatically paid out to the wallet that initially activated and minted the referral code, directly from the smart contract.</p>
              </div>

              <div>
                <h5 className="font-bold mb-2">Unlimited Referrals</h5>
                <p className="terminal-text">There is no limit to the number of referrals a holder can make. Rewards are distributed on a first-come, first-served basis.</p>
              </div>

              <div>
                <h5 className="font-bold mb-2">Prohibition of Fraudulent Activities</h5>
                <p className="terminal-text">No Fake Referrals: Creating alternate accounts or engaging in any activity to generate fake referrals is strictly prohibited.</p>
                <p className="terminal-text mt-2">Penalties for Violation: Violators will be banned from accessing all products and services offered by Waifuverse and may face additional penalties.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ),

  collection: (
    <div className="space-y-8 font-['Press_Start_2P'] animate-fadeIn p-4">
      <h2 className="text-xl sm:text-2xl text-cyan-400 animate-glitch hover:scale-105 transition-transform">{'>'} COLLECTION_TIERS</h2>
      
      {[
        {
          tier: "MORTAL TIER",
          amount: "4,444",
          percentage: "50%",
          emoji: "⚔️"
        },
        {
          tier: "MYTHIC TIER",
          amount: "2,222",
          percentage: "25%",
          emoji: "☀️"
        },
        {
          tier: "ASCENDED TIER",
          amount: "1,333",
          percentage: "15%",
          emoji: "👑"
        },
        {
          tier: "TITAN TIER",
          amount: "533",
          percentage: "6%",
          emoji: "🛡️"
        },
        {
          tier: "DIVINE TIER",
          amount: "267",
          percentage: "3%",
          emoji: "✨"
        },
        {
          tier: "COSMIC CREATOR TIER",
          amount: "66",
          percentage: "0.75%",
          emoji: "🌀"
        },
        {
          tier: "SPECIAL EDITIONS",
          amount: "22",
          percentage: "0.25%",
          emoji: "🌙"
        }
      ].map((tier, index) => (
        <div 
          key={index}
          className="group hover:scale-105 transition-all duration-500 p-4 rounded-lg bg-cyan-900/5 hover:bg-cyan-900/15"
          onClick={() => playSound('/button-click.mp3', 0.15)}
        >
          <div className="flex items-center gap-4">
            <span className="text-2xl">{tier.emoji}</span>
            <div>
              <h3 className="text-cyan-400 text-lg sm:text-xl group-hover:text-cyan-300">{'>'} {tier.tier}</h3>
              <p className="mt-2 terminal-text animate-typing text-sm sm:text-base group-hover:text-cyan-300">
                {tier.amount} ({tier.percentage})
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  ),

  rights: (
    <div className="space-y-8 font-['Press_Start_2P'] text-cyan-400 animate-fadeIn p-4">
      <h2 className="text-xl sm:text-2xl animate-glitch hover:scale-105 transition-transform">{'>'} LICENSE_DATA</h2>
      
      {[
        {
          title: "HOLDER_RIGHTS",
          content: "Holders can license their IPs according to preferences, subject to legal compliance and DMCA guidelines."
        },
        {
          title: "IP_RIGHTS", 
          content: "All intellectual property rights reserved by WAIFUVERSE and its affiliates."
        }
      ].map((section, index) => (
        <div 
          key={index} 
          className="space-y-4 hover:scale-105 transition-all duration-500 p-4 rounded-lg bg-cyan-900/5 hover:bg-cyan-900/15 group"
          onClick={() => playSound('/button-click.mp3', 0.15)}
        >
          <h3 className="text-lg sm:text-xl group-hover:text-cyan-300">{'>'} {section.title}</h3>
          <p className="leading-relaxed terminal-text animate-typing text-sm sm:text-base group-hover:text-cyan-300">
            {section.content}
          </p>
        </div>
      ))}
    </div>
  ),
  community: (
    <div className="space-y-8 font-['Press_Start_2P'] text-cyan-400 animate-fadeIn p-4">
      <h2 className="text-xl sm:text-2xl animate-glitch hover:scale-105 transition-transform">{'>'} COMMUNITY_DATA</h2>
      
      <div className="relative inline-block overflow-hidden rounded-lg border-2 border-cyan-400 group hover:border-cyan-300 transition-colors duration-500 w-full max-w-[350px] mx-auto">
        <div className="bg-cyan-900/10 group-hover:bg-cyan-900/20 transition-all w-full">
          <iframe 
            src="https://discord.com/widget?id=1232726915740078101&theme=dark" 
            width="100%"
            height="500" 
            allowTransparency="true" 
            frameBorder="0" 
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            className="rounded-lg w-full"
            style={{
              filter: 'drop-shadow(0 0 10px #0ff)',
              maxWidth: '100%'
            }}
          />
        </div>
        <div className="absolute inset-0 pointer-events-none">
          <div className="absolute inset-0 bg-gradient-to-r from-cyan-500/0 via-cyan-500/10 to-cyan-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-cyan-400/5 to-transparent animate-scan-vertical" />
        </div>
      </div>

      <div className="space-y-6">
        {[
          {
            href: "https://x.com/Waifuverse_io", 
            text: "FOLLOW_TWITTER"
          },
        ].map((link, index) => (
          <a 
            key={index}
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => playSound('/button-click.mp3')}
            className="flex items-center space-x-2 px-4 sm:px-6 py-2 sm:py-3 border-2 border-cyan-400 
                       hover:bg-cyan-400/10 hover:scale-105 transition-all duration-500 w-fit text-sm sm:text-base
                       group relative overflow-hidden rounded-lg"
          >
            <span className="relative z-10 group-hover:text-cyan-300">{'>'} {link.text}</span>
            <ExternalLink size={16} className="animate-pulse sm:w-5 sm:h-5 relative z-10 group-hover:text-cyan-300" />
            <div className="absolute inset-0 bg-gradient-to-r from-cyan-500/0 via-cyan-500/10 to-cyan-500/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000" />
          </a>
        ))}
      </div>

      <div className="relative inline-block overflow-hidden rounded-lg border-2 border-cyan-400 group hover:border-cyan-300 transition-colors duration-500 w-full">
        <div className="bg-cyan-900/10 group-hover:bg-cyan-900/20 transition-all w-full">
          <iframe 
            src="https://www3.cbox.ws/box/?boxid=3542598&boxtag=tkn4Pf"
            width="100%"
            height="450"
            allowTransparency="yes"
            allow="autoplay"
            frameBorder="0"
            marginHeight="0" 
            marginWidth="0"
            scrolling="auto"
            className="rounded-lg w-full"
            style={{
              filter: 'drop-shadow(0 0 10px #0ff)',
              maxWidth: '100%'
            }}
          />
        </div>
        <div className="absolute inset-0 pointer-events-none">
          <div className="absolute inset-0 bg-gradient-to-r from-cyan-500/0 via-cyan-500/10 to-cyan-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-cyan-400/5 to-transparent animate-scan-vertical" />
        </div>
      </div>

      <a
        href="https://fauux.neocities.org/Love"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => playSound('/button-click.mp3', 0.3)}
        className="block w-full max-w-[350px] mx-auto px-6 py-3 text-center border-2 border-red-500 
                   text-red-500 hover:text-red-300 hover:border-red-300 
                   hover:bg-red-500/10 hover:scale-105 transition-all duration-500
                   animate-glitch relative overflow-hidden rounded-lg group"
        style={{
          textShadow: '0 0 10px #f00, 0 0 20px #f00',
          filter: 'drop-shadow(0 0 15px #f00)'
        }}
      >
        <span className="relative z-10 font-['Press_Start_2P'] text-lg">DO NOT CLICK</span>
        <div className="absolute inset-0 bg-gradient-to-r from-red-500/0 via-red-500/10 to-red-500/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-red-400/5 to-transparent animate-scan-vertical" />
      </a>

      <div className="h-8"></div>

    </div>
  ),
};

// Enhanced loading spinner with matrix rain effect and performance optimizations 
const LoadingSpinner = React.memo(({ progress }) => {
  const [rainDrops, setRainDrops] = useState([]);
  const matrixChars = 'ワイフユニバースABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  useEffect(() => {
    const interval = setInterval(() => {
      setRainDrops(prev => {
        const newDrops = [...prev];
        if (newDrops.length < 20) { // Reduced number of drops
          newDrops.push({
            x: Math.random() * window.innerWidth,
            y: -20,
            speed: 0.3 + Math.random() * 1,
            char: matrixChars[Math.floor(Math.random() * matrixChars.length)],
            opacity: 0.1 + Math.random() * 0.2
          });
        }
        return newDrops.map(drop => ({
          ...drop,
          y: drop.y + drop.speed,
          char: Math.random() > 0.98 ? matrixChars[Math.floor(Math.random() * matrixChars.length)] : drop.char
        })).filter(drop => drop.y < window.innerHeight);
      });
    }, 60);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative flex flex-col justify-center items-center py-4 sm:py-8 space-y-4">
      {rainDrops.map((drop, i) => (
        <div
          key={i}
          className="absolute text-cyan-400 font-mono animate-pulse text-sm sm:text-base"
          style={{
            left: drop.x,
            top: drop.y,
            opacity: drop.opacity
          }}
        >
          {drop.char}
        </div>
      ))}
      <div className="animate-pulse text-xl sm:text-2xl font-['Press_Start_2P'] z-10">
        LOADING<span className="animate-bounce">...</span>
      </div>
      {progress && (
        <div className="w-36 sm:w-48 h-2 bg-cyan-900/50 rounded-full z-10">
          <div 
            className="h-full bg-cyan-400 rounded-full transition-all duration-300"
            style={{ width: `${progress}%` }}
          />
        </div>
      )}
    </div>
  );
});
// Enhanced terminal-style title with performance optimizations
const GlitchTitle = React.memo(() => {
  // Add useEffect to handle audio
  useEffect(() => {
    const audio = document.getElementById('background-music');
    if (audio) {
      audio.volume = 0.1; // Set volume to 5%
    }
  }, []); // Empty dependency array as we only want this to run once

  return (
    <div className="text-center mb-4 sm:mb-8 relative" onClick={() => playSound('/hover.mp3', 0.3)}>
      <audio id="background-music" autoPlay loop>
        <source src="/background.mp3" type="audio/mpeg" />
      </audio>
      <h1 
        className="text-xl sm:text-3xl md:text-6xl font-['Press_Start_2P'] text-cyan-400 animate-pulse"
        style={{
          textShadow: '0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #0ff',
          letterSpacing: '0.1em',
          wordBreak: 'break-word'
        }}
      >
        WAIFU<span className="text-pink-400">VERSE</span>
        <span className="block mt-2 text-lg sm:text-2xl hover:text-pink-400 transition-colors">
          ワイフユニバース
        </span>
      </h1>
    </div>
  );
});

// Main App component with enhanced performance optimizations
const App = () => {
  const [activeSection, setActiveSection] = useState('introduction');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);

  useEffect(() => {
    if (showIntro) {
      const introTimer = setTimeout(() => {
        setShowIntro(false);
        setShowLoadingScreen(true);
      }, 5000);

      const loadingTimer = setTimeout(() => {
        setShowLoadingScreen(false);
      }, 10000); // Show loading screen for additional 5 seconds

      return () => {
        clearTimeout(introTimer);
        clearTimeout(loadingTimer);
      };
    }
  }, [showIntro]);

  const handleLogout = useCallback(() => {
    playSound('/button-click.mp3');
    setIsLoggedIn(false);
    setShowIntro(true);
    setShowLoadingScreen(false);
  }, []);

  if (showIntro) {
    return (
      <div className="min-h-screen bg-black">
        <MatrixRain />
        <BootTitle onComplete={() => setShowIntro(false)} />
      </div>
    );
  }

  if (showLoadingScreen) {
    return <LoadingScreen onLoadingComplete={() => setShowLoadingScreen(false)} />;
  }

  if (!isLoggedIn) {
    return <Login onLogin={() => setIsLoggedIn(true)} />;
  }

  return (
    <div className="min-h-screen bg-black text-cyan-400 p-4 sm:p-8">
      <MatrixRain />
      <GlitchTitle />
      <div className="max-w-4xl mx-auto">
        <div className="flex flex-wrap gap-2 sm:gap-4 mb-4 sm:mb-8">
          {Object.keys(content).map(section => (
            <button
              key={section}
              onClick={() => {
                setActiveSection(section);
                playSound('/button-click.mp3');
              }}
              className={`px-3 py-1 sm:px-4 sm:py-2 font-['Press_Start_2P'] border-2 text-sm sm:text-base
                         ${activeSection === section ? 'border-cyan-400 bg-cyan-400/20' : 'border-cyan-400/50'} 
                         hover:bg-cyan-400/10 transition-all duration-300`}
            >
              {'>'} {section.toUpperCase()}
            </button>
          ))}
        </div>
        <div className="animate-fadeIn bg-black/30 backdrop-blur-sm p-4 rounded-lg">
          {content[activeSection]}
        </div>
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50">
          <button
            onClick={handleLogout}
            className="px-3 py-1 sm:px-4 sm:py-2 font-['Press_Start_2P'] border-2 border-cyan-400 
                     hover:bg-cyan-400/10 transition-all duration-300 cursor-pointer text-sm sm:text-base
                     bg-black/30 backdrop-blur-sm"
          >
            {'>'} LOGOUT
          </button>
        </div>
      </div>
    </div>
  );
};

export default App;
export { content, LoadingSpinner, GlitchTitle, playSound };
