import React from 'react';
import ReactDOM from 'react-dom/client';
// Ensure your TailwindCSS file is correctly imported
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Create the root for your application
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// For performance measurement (optional, leave as is)
reportWebVitals();
